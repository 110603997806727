@import "common.scss";

@font-face {
  font-family: 'Gen Shin Gothic P';
  src: url('../../public/static/fonts/GenShinGothic-P-Normal.ttf');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
}
html {
  font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
  background-color: #FFFFFF !important;
  @media (min-width: 640px) { font-size: 10px; }
  @media (min-width: 768px) { font-size: 10px; }
  @media (min-width: 1024px) { font-size: 10px; }
  @media (min-width: 1280px) { font-size: 10px; }
  @media (min-width: 1536px) { font-size: 14px; }
}

.layout .main{
  width: 100%;
  height: calc(100% - 72px);
  overflow: auto;
}
